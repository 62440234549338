import BigNumber from 'bignumber.js';
import { defineStore } from 'pinia';
import { computed, ComputedRef, reactive, ref, unref, watch } from 'vue';
import { CRYPTO_SDK_VERSION } from 'crypto-sdk';
import { BIG_ZERO } from '@/utils/bigNumber';
import { Token } from '@/sdk/entities/token';
import { compareTokenAddresses } from '@/sdk/utils';
import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import {
  getBlockchainById,
  getBlockchainByName,
} from '@/store/modules/bridge/constants/BLOCKCHAINS';
import { BRIDGE_TOKENS } from '@/store/modules/bridge/constants/BRIDGE_TOKENS';
import {
  isCardanoMainnetOrTestnet,
  isMilkomedaMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { Blockchain } from '@/store/modules/bridge/models/blockchain';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import { BridgeToken } from '@/store/modules/bridge/models/bridge-token';
import { Direction } from '@/store/modules/bridge/models/direction';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useBridgeCalculations } from '@/composables/bridge/useBridgeCalculations';
import { useBridgeValidator } from '@/composables/bridge/useBridgeValidator';
import { useBridgeFromCardanoToMilkomeda } from '@/composables/bridge/useBridgeFromCardanoToMilkomeda';
import { useBridgeFromMilkomedaToCardano } from '@/composables/bridge/useBridgeFromMilkomedaToCardano';

console.debug('sdk v', CRYPTO_SDK_VERSION);

export const useCardanoBridge = defineStore('cardanoBridge', () => {
  const { walletState } = useEVMWallet();
  const cardanoWallet = useCardanoWallet();
  const { doBridgeFromCardanoToMilkomeda } = useBridgeFromCardanoToMilkomeda();
  const { doBridgeFromMilkomedaToCardano } = useBridgeFromMilkomedaToCardano();
  const { balances } = useBalances();
  const {
    calculateMaxAmount: calculateMaxBridgeAmount,
    calculateAmountToAndNetworkFee,
    calculateReceiveGasTokensAndGasTokensFee,
  } = useBridgeCalculations();
  const { validateFormByNetworkAndToken: validateForm } = useBridgeValidator();

  const currentBlockChain = ref<BLOCKCHAIN_NAME>(getCurrentBlockchainsNames()[0]);
  console.log('currentBlockChain', currentBlockChain);

  const bridgeTokens = ref<BridgeToken[]>(getBridgeTokens());
  console.log('bridgeTokens', bridgeTokens);
  const bridgeForm = reactive<BridgeForm>(
    getInitializedBridgeForm(bridgeTokens.value as BridgeToken[], unref(currentBlockChain)),
  );

  const getBlockchainTokens = (
    blockchainName: BLOCKCHAIN_NAME | ComputedRef<BLOCKCHAIN_NAME>,
  ): ComputedRef<Token[]> => {
    return computed(() => {
      const bridgeTokensForThisBlockchain = bridgeTokens.value.filter(
        token =>
          token.blockchain1.name === unref(blockchainName) ||
          token.blockchain2.name === unref(blockchainName),
      );

      return bridgeTokensForThisBlockchain.map(bridgeToken =>
        bridgeToken.blockchain1.name === unref(blockchainName)
          ? bridgeToken.token1
          : bridgeToken.token2,
      );
    });
  };

  const getBlockchainByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<Blockchain> => {
    return computed(() =>
      unref(direction) === 'from' ? bridgeForm.fromBlockchain : bridgeForm.toBlockchain,
    );
  };

  const getTokenByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<Token> => {
    return computed(() =>
      unref(direction) === 'from' ? bridgeForm.fromToken : bridgeForm.toToken,
    );
  };

  const getAmountByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<BigNumber | null> => {
    return computed(() =>
      unref(direction) === 'from'
        ? (bridgeForm.fromAmount as BigNumber)
        : (bridgeForm.toAmount as BigNumber),
    );
  };

  const getGasToken = () => {
    return isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)
      ? bridgeTokens.value[0].token1
      : bridgeTokens.value[0].token2;
  };

  const getMilkomedaGasTokenBalance = () => {
    return balances?.[getGasToken().unwrapWETH().symbol!]?.balance.toExact() || 0;
  };

  const getMilkomedaTokenBalance = (token: Token) => {
    return balances?.[token.unwrapWETH().symbol!]?.balance.toExact() || 0;
  };

  const setFromAmount = (amount: string, formFromAmount: string = amount): void => {
    bridgeForm.fromAmount = new BigNumber(amount ? amount : 0);
    bridgeForm.formFromAmount = formFromAmount;
  };

  async function calculateMaxAmount(balance: BigNumber): Promise<BigNumber> {
    return await calculateMaxBridgeAmount(bridgeForm as BridgeForm, balance);
  }

  const resetBridgeErrors = () => {
    bridgeForm.errors = [];
  };

  async function validateFormByNetworkAndToken() {
    resetBridgeErrors();
    await validateForm(bridgeForm as BridgeForm);
  }

  const setSelectedToken = (direction: Direction, token: Token): void => {
    const currentBlockchain = getBlockchainByDirection(direction).value;

    bridgeForm.bridgeToken = bridgeTokens.value.find(
      bridgeToken =>
        (compareTokenAddresses(bridgeToken.token1.address, token.address) &&
          bridgeToken.blockchain1 === currentBlockchain) ||
        (compareTokenAddresses(bridgeToken.token2.address, token.address) &&
          bridgeToken.blockchain2 === currentBlockchain),
    )!;

    const oppositeBridgeToken = compareTokenAddresses(
      bridgeForm.bridgeToken.token1.address,
      token.address,
    )
      ? bridgeForm.bridgeToken.token2
      : bridgeForm.bridgeToken.token1;

    if (direction === 'from') {
      bridgeForm.fromToken = token;
      bridgeForm.toToken = oppositeBridgeToken;
    } else {
      bridgeForm.toToken = token;
      bridgeForm.fromToken = oppositeBridgeToken;
    }
  };

  async function onFormChange() {
    console.groupCollapsed('[BRIDGE] onFormChange');

    const { receiveGasTokens, gasTokensFee } = calculateReceiveGasTokensAndGasTokensFee(
      bridgeForm as BridgeForm,
    );
    console.log('receive GAS tokens : ', receiveGasTokens.toString());
    console.log('GAS tokens fee : ', gasTokensFee.toString());
    bridgeForm.receiveGasTokens = receiveGasTokens;
    bridgeForm.gasTokensFee = gasTokensFee;

    const { toAmount, networkFee } = await calculateAmountToAndNetworkFee(bridgeForm as BridgeForm);
    console.log('amount TO : ', toAmount.toString());
    console.log('network fee: ', networkFee.toString());
    bridgeForm.toAmount = toAmount;
    bridgeForm.networkFee = networkFee;

    console.groupEnd();
  }

  async function switchBlockchains(): Promise<void> {
    currentBlockChain.value = bridgeForm.toBlockchain.name;

    const tokenFrom = bridgeForm.toToken;
    const formFromAmount = bridgeForm.formFromAmount;

    const newForm = getInitializedBridgeForm(
      bridgeTokens.value as BridgeToken[],
      unref(currentBlockChain),
    );

    Object.assign(bridgeForm, newForm);
    setSelectedToken('from', tokenFrom);
    setFromAmount(formFromAmount);

    await validateFormByNetworkAndToken();
  }

  watch(() => bridgeForm.fromAmount, onFormChange);
  watch(() => bridgeForm.bridgeToken, onFormChange);
  watch(
    () => cardanoWallet.address,
    val => {
      if (val) {
        onFormChange();
      }
    },
  );

  async function doBridge() {
    console.groupCollapsed('[BRIDGE] doBridge');

    const cBridgeForm: BridgeForm = Object.assign({}, bridgeForm) as BridgeForm;

    try {
      if (!walletState.account) {
        console.error('[BRIDGE] Can not get account from wallet.');

        throw Error('Can not get account from wallet for bridge.');
      }

      setFromAmount('0', '');

      if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
        await doBridgeFromCardanoToMilkomeda(cBridgeForm, walletState.account);
      } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
        await doBridgeFromMilkomedaToCardano(cBridgeForm, walletState.account);
      }
    } catch (error) {
      console.error(`[BRIDGE] Happen error during bridge operation. ERROR : `, error);
    } finally {
      console.groupEnd();
    }
  }

  return {
    bridgeTokens,
    bridgeForm,
    getBlockchainTokens,
    getBlockchainByDirection,
    getTokenByDirection,
    getAmountByDirection,
    getGasToken,
    setSelectedToken,
    setFromAmount,
    resetBridgeErrors,
    doBridge,
    validateFormByNetworkAndToken,
    calculateMaxAmount,
    switchBlockchains,
    getMilkomedaGasTokenBalance,
    getMilkomedaTokenBalance,
  };
});

function getBridgeTokens(): BridgeToken[] {
  const currentBlockchainNames = getCurrentBlockchainsNames();

  const rawBridgeTokens = BRIDGE_TOKENS.filter(
    rawBridgeToken =>
      (rawBridgeToken.blockchain1 === currentBlockchainNames[0] &&
        rawBridgeToken.blockchain2 === currentBlockchainNames[1]) ||
      (rawBridgeToken.blockchain1 === currentBlockchainNames[1] &&
        rawBridgeToken.blockchain2 === currentBlockchainNames[0]),
  );

  return rawBridgeTokens.map(makeBridgeTokenByRawBridgeToken);
}

function getCurrentBlockchainsNames(): [BLOCKCHAIN_NAME, BLOCKCHAIN_NAME] {
  const milkomedaName = getBlockchainById(
    Number(DEFAULT_NETWORK_ID ?? getBlockchainByName(BLOCKCHAIN_NAME.MILKOMEDA).id),
  ).name;
  const cardanoName = getBlockchainById(
    Number(
      process.env.VUE_APP_DEFAULT_CARDANO_ID ?? getBlockchainByName(BLOCKCHAIN_NAME.CARDANO).id,
    ),
  ).name;

  return [cardanoName, milkomedaName];
}

function makeBridgeTokenByRawBridgeToken(
  rawBridgeToken: typeof BRIDGE_TOKENS[number],
): BridgeToken {
  const { getTokenByAddressAndChainId } = useTokens();

  const cardanoTokenNumber = isCardanoMainnetOrTestnet(rawBridgeToken.blockchain1) ? 1 : 2;
  const milkomedaTokenNumber = cardanoTokenNumber === 1 ? 2 : 1;
  const cardanoTokenAddress = rawBridgeToken[`tokenAddress${cardanoTokenNumber}`];
  const milkomedaTokenAddress = rawBridgeToken[`tokenAddress${milkomedaTokenNumber}`];

  const milkomedaToken = getTokenByAddressAndChainId(
    milkomedaTokenAddress,
    getBlockchainByName(rawBridgeToken[`blockchain${milkomedaTokenNumber}`]).id,
  );

  if (!milkomedaToken) {
    throw new Error(`Milkomeda token ${milkomedaTokenAddress} was not found.`);
  }

  const cardanoToken = useCardanoTokens().getTokenByAddress(cardanoTokenAddress);

  const cardanoTokenLikeERC20 = new Token(
    getBlockchainByName(rawBridgeToken[`blockchain${cardanoTokenNumber}`]).id,
    cardanoTokenAddress,
    cardanoToken.decimals,
    cardanoToken.symbol,
    cardanoToken.name,
    milkomedaToken.projectLink,
    milkomedaToken.tokenIconUrl,
  );

  return {
    blockchain1: getBlockchainByName(rawBridgeToken.blockchain1),
    blockchain2: getBlockchainByName(rawBridgeToken.blockchain2),
    token1: cardanoTokenNumber === 1 ? cardanoTokenLikeERC20 : milkomedaToken,
    token2: cardanoTokenNumber === 2 ? cardanoTokenLikeERC20 : milkomedaToken,
    blockchain1Fee: new BigNumber(rawBridgeToken.blockchain1Fee),
    blockchain2Fee: new BigNumber(rawBridgeToken.blockchain2Fee),
  };
}

function getInitializedBridgeForm(
  bridgeTokens: BridgeToken[],
  currentBlockChain: BLOCKCHAIN_NAME,
): BridgeForm {
  const defaultFormAmount = 0;
  const bridgeToken = bridgeTokens[0];
  const [fromBlockchain, toBlockchain] = isCardanoMainnetOrTestnet(currentBlockChain)
    ? [bridgeToken.blockchain1, bridgeToken.blockchain2]
    : [bridgeToken.blockchain2, bridgeToken.blockchain1];

  const [fromToken, toToken] = isCardanoMainnetOrTestnet(currentBlockChain)
    ? [bridgeToken.token1, bridgeToken.token2]
    : [bridgeToken.token2, bridgeToken.token1];

  return {
    bridgeToken,
    fromBlockchain,
    toBlockchain,
    fromToken,
    toToken,
    fromAmount: new BigNumber(defaultFormAmount),
    formFromAmount: '',
    toAmount: BIG_ZERO,
    networkFee: BIG_ZERO,
    receiveGasTokens: BIG_ZERO,
    gasTokensFee: BIG_ZERO,
    errors: [],
  };
}
